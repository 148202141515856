<template>
  <div class="feedback">
    <h1 class="feedback__title">
      Задайте вопрос нашим специалистам
    </h1>
    <form class="feedback__form" novalidate @submit.prevent="validate()">
      <Field v-model="form.name" title="Имя" :error="formErrors.name" />
      <Phone v-model="form.phone" title="Телефон" :error="formErrors.phone" />
      <Field v-model="form.email" type="email" title="E-mail" :error="formErrors.email" />
      <Field
        v-model="form.text"
        title="Напишите ваш вопрос"
        :error="formErrors.name"
        textarea
      />
      <Button type="submit">
        Отправить
      </Button>
      <p class="feedback__description">
        Нажимая на кнопку, вы соглашаетесь с
        <a href="#" target="_blank">Правилами политики конфиденциальности</a>
      </p>
    </form>
  </div>
</template>

<script>
import popup from '@/mixins/popup';
import Field from '@/components/gui/field/Field';
import Phone from '@/components/gui/field/Phone';
import Button from '@/components/gui/button/Button';

import Validation from '@/ext/validation/ValidationWithFocus';

import './feedback.scss';

export default {
  name: 'Feedback',
  components: {
    Field,
    Phone,
    Button
  },
  mixins: [popup],
  data() {
    return {
      formIsSubmit: false,
      form: {
        name: '',
        phone: '',
        email: '',
        text: ''
      },
      formErrors: {
        name: '',
        phone: '',
        email: '',
        text: ''
      },
      formRules: {
        name: [Validation.REQUIRED],
        phone: [Validation.PHONE],
        email: [Validation.REQUIRED, Validation.EMAIL],
        text: [Validation.REQUIRED]
      }
    };
  },
  watch: {
    formIsSubmit() {
      this.$watch('form', () => this.validate(false), { deep: true });
    }
  },
  methods: {
    validate(submit = true) {
      this.formIsSubmit = true;
      const selector = submit ? '.field_error input' : null;
      const validation = new Validation(
        selector,
        this.form,
        this.formRules
      ).make();

      this.formErrors = validation.errors;

      submit && validation.isValid && this.submit();
    },
    submit() {
      alert('тут отправляем почту');
    }
  }
};
</script>
